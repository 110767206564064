@import "../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";


$name: '.BrowseByIndustry';

#{$name} {
    position: relative;

    &-title {
        text-align: center;
        font-size: 1.25rem;
    }

    &-listWrapper {
        position: relative;
    }

    &-navigate {
        display: none;

        @include media-breakpoint-up(lg) {
            position: absolute;
            display: block;
            top: 0;
            width: 3rem;
            height: 100%;
            border: none;
            padding: 0;
            z-index: 10;
            cursor: pointer;
            outline: 0;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 13px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            &--right, &--left {
                background-color: rgba($white, .95);
                box-shadow: 0 0 12px rgba(0,0,0,.15);

                &.is-hidden {
                    display: none;
                }
            }

            &--right {
                right: 0;

                &::after {
                    @include icon-chevron-right($primary);
                }
            }

            &--left {
                left: 0;

                &::after {
                    @include icon-chevron-left($primary);
                }
            }
        }
    }

    &-level1 {
        &List {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            position: relative;
            overflow-x: auto;

            @include media-breakpoint-up(lg) {
                overflow: hidden;
            }

            &Item {
                display: flex;

                @media all and (-ms-high-contrast:none) {
                    //IE11 fix
                    *::-ms-backdrop, & {
                        flex-shrink: 0;
                    }
                }
            }
        }

        &Btn {
            border: none;
            background-color: transparent;
            margin: 0;
            text-align: center;
            cursor: pointer;
            font-family: $headings-font-family;
            line-height: 1;
            font-size: .765rem;
            padding: 1rem .5rem;

            @include media-breakpoint-up(lg) {
                font-size: 0.875rem;
                padding: 1rem 2rem;
            }

            &.is-active {
                background-color: $sand-light;
            }
        }

        &Icon {
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 auto .5rem;

            @include media-breakpoint-up(lg) {
                width: 60px;
                height: 60px;
            }
        }
    }

    &-level2 {

        &List {
            list-style-type: none;
            display: none;
            padding: 1rem 1rem .5rem;

            &.is-visible {
                display: flex;
                flex-wrap: wrap;
            }

            &Item {
                text-align: center;
            }
        }


        &Link {
            border: 1px solid $gray-300;
            background-color: $white;
            padding: .4rem .8rem;
            border-radius: 50px;
            display: inline-block;
            cursor: pointer;
            font-size: .656rem;
            color: $body-color;

            &:hover {
                border-color: $gray-500;
                text-decoration: none;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: $sand-light;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $gray-400;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: $gray-400;
    }
}
